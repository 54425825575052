import { SVLRouteValue } from '@domain/shared/svl-route-value';
import { CoreRouteNames } from '../core.routenames';

interface UsersRouteNamesType {
    list: SVLRouteValue;
    detail: SVLRouteValue;
    create: SVLRouteValue;
}

export const UsersRouteNames: UsersRouteNamesType = {
    list: { local: '', global: CoreRouteNames.users.global },
    detail: {
        local: 'detail/:id',
        global: CoreRouteNames.users.global + '/detail',
    },
    create: {
        local: 'create',
        global: CoreRouteNames.users.global + '/create',
    },
};
